import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import CloudflareImage from "../reusable-components/cloudflare-image"

import BlogMetaData from "../../components/blog-components/blog-meta-data"

import FlexSection from "../../components/reusable-components/layout/flex-section"
import LinkWrapper from "../../components/reusable-components/link-wrapper"
import SubmitButton from "../../components/reusable-components/buttons/submit-button"

import { getProperty } from "../../lib/utils"

const BlogPostCard = ({ post, blogBaseUrl, className = "" }) => {
  const title = getProperty(post, ["title"], "")
  const slug = getProperty(post, ["slug"], "")
  const excerpt = getProperty(post, ["excerpt"], "")
  const datum = getProperty(post, ["date"], "")
  const image = getProperty(post, ["featuredImage", "node", "sourceUrl"], false)
  return (
    <BlogPostCardStyling
      direction="row"
      className={`full-width-inner-section ${className}`}
      margin="0 0 20px"
    >
      {!image ? null : (
        <StyledImg
          imgStyle={{
            width: "850px",
            objectFit: "cover",
          }}
          objectFit="cover"
          objectPosition="0 50%"
          src={image}
          className="full-width-inner-section"
        />
      )}
      <BlogPostCardContentStyling
        margin="0"
        className="full-width-inner-section"
      >
        <LinkWrapper to={`${blogBaseUrl}${slug}`}>
          <h5 dangerouslySetInnerHTML={{ __html: title }} />
        </LinkWrapper>
        <div dangerouslySetInnerHTML={{ __html: excerpt }} />
        <BlogMetaData datum={datum} />
        <SubmitButton
          text="Read article"
          callbackFunction={() => {
            navigate(`${blogBaseUrl}${slug}`)
          }}
        />
      </BlogPostCardContentStyling>
    </BlogPostCardStyling>
  )
}

// ===============
//     STYLES
// ===============
const BlogPostCardStyling = styled(FlexSection)`
  height: 404px;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    height: 704px;
  }
`
const BlogPostCardContentStyling = styled(FlexSection)`
  width: 51%;
  height: 100%;
  padding: 60px 55px 0;
  justify-content: space-between;
  align-items: space-between;
  h5,
  div {
    margin: 0 0 0;
  }
  p {
    margin: 0;
  }
  button {
    background-color: ${({ theme }) => theme.colors.lightgrey3};
    border-color: ${({ theme }) => theme.colors.lightgrey3};
    transition: all 0.2s ease-in-out;
    border-radius: 50px;
  }
  button:hover {
    background-color: ${({ theme }) => theme.colors.grey};
    border-color: ${({ theme }) => theme.colors.grey};
    transition: all 0.2s ease-in-out;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding: 30px 0 0;
    &.full-width-inner-section,
    h5,
    div {
      margin: 0;
    }
  }
`
const StyledImg = styled(CloudflareImage)`
  height: 100%;
  width: 49%;
  img {
    left: unset;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  & div {
    padding-bottom: 100% !important;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    &.full-width-inner-section {
      margin-bottom: 0rem;
    }
  }
`

export default BlogPostCard
