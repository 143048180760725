import React from "react"
import styled from "styled-components"
import { graphql, navigate } from "gatsby"

import Layout from "../../components/theme/layout"
import SEO from "../../components/theme/header/seo"
import BlogPostCard from "../../components/blog-components/blog-post-card"
import Pagination from "../../components/blog-components/pagination"
import DividerLine from "../../components/reusable-components/divider-line"

import { getProperty } from "../../lib/utils"

const BlogCategoryPage = ({ pageContext, data, location }) => {
  const title = getProperty(pageContext, ["pageTitle"], "")
  const blogBaseUrl = getProperty(pageContext, ["blogBaseUrl"], "")
  const categoryBaseUrl = getProperty(pageContext, ["categoryBaseUrl"], "")
  const pageNumber = getProperty(pageContext, ["pageNumber"], 0)
  const totalPages = getProperty(pageContext, ["totalPages"], 0)
  const category = getProperty(pageContext, ["category"], "Blog")
  const description = getProperty(pageContext, ["categoryDescription"], "")
  const posts = getProperty(pageContext, ["posts"], [])
  const postsAdditionalData = getProperty(data, ["allWpPost", "nodes"], [])
  const categoryToUrlMap = getProperty(pageContext, ["categoryPageUrls"], false)
  const categoriesArePopulated =
    categoryToUrlMap &&
    !(
      typeof categoryToUrlMap == "object" &&
      Object.keys(categoryToUrlMap).length === 0
    )

  const excerptLength = 200
  for (let i = 0; i < posts.length; ++i) {
    // filter the postsAdditionalData databaseId to only include current post databaseId from the for loop
    // and for each post slice the content up to create the excerpt
    const node = postsAdditionalData.filter((node) => {
      return node.databaseId === posts[i].databaseId
    })
    let excerpt = node.length && node[0].content
    if (excerpt) {
      const shortExcerpt = excerpt.slice(0, excerptLength)
      if (shortExcerpt.length != excerpt.length) {
        excerpt = `${shortExcerpt}[...]`
      } else {
        excerpt = shortExcerpt
      }
    }
    posts[i].excerpt = excerpt
    posts[i].featuredImage = node.length && node[0].featuredImage
  }

  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO
        title={getProperty(data, ["seaBlogCategory", "seo", "title"], "")}
        location={location}
        yoast={getProperty(data, ["seaBlogCategory", "seo"], {})}
      />
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <h2>{`All articles${!category ? "" : ` in ${category}`}`}</h2>
      {!description ? null : (
        <h3 dangerouslySetInnerHTML={{ __html: description }} />
      )}
      {!categoriesArePopulated ? null : (
        <div>
          <StyledSelect
            onChange={(event) => {
              navigate(event.target.value)
            }}
          >
            <option key={"default"} value={blogBaseUrl}>
              {`Category${!category ? "" : `: ${category}`}`}
            </option>
            {!category ? null : (
              <option key={blogBaseUrl} value={blogBaseUrl}>
                {"All"}
              </option>
            )}
            {Object.entries(categoryToUrlMap).map(([cat, url]) =>
              cat == category ? null : (
                <option key={url} value={url}>
                  {cat}
                </option>
              )
            )}
          </StyledSelect>
        </div>
      )}
      {!posts.length
        ? `There are no posts under the ${category} category`
        : posts.map((post, postIndex) => (
            <BlogPostCard
              key={postIndex}
              post={post}
              blogBaseUrl={blogBaseUrl}
            />
          ))}
      <DividerLine margin="50px auto" />
      <Pagination
        pageNumber={pageNumber}
        totalPages={totalPages}
        categoryBaseUrl={categoryBaseUrl}
      />
    </Layout>
  )
}

export default BlogCategoryPage

// ===============
//     STYLES
// ===============
const StyledSelect = styled.select`
  width: 100%;
  padding: 1rem 20px;
  option {
    padding: 1rem 0;
  }
  margin: 50px 0 60px;
  border: 1px solid transparent;
  outline: none;
  background-color: ${({ theme }) => theme.colors.lightgrey2};
  @media (min-width: ${({ theme }) => theme.breakMedium}) {
    border-right-width: 20px;
    width: 300px;
  }
`

// ===============
//      QUERY
// ===============
export const blogPageQueryData = graphql`
  query GET_BLOG_PAGE_QUERY_DATA($categoryId: Int!, $postIds: [Int]) {
    wpCategory(databaseId: { eq: $categoryId }) {
      slug
    }
    allWpPost(filter: { databaseId: { in: $postIds } }) {
      nodes {
        databaseId
        content
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
    seaBlogCategory(database_id: { eq: $categoryId }) {
      seo {
        description
        meta_description
        og_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
  }
`
