import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import SubmitButton from "../reusable-components/buttons/submit-button"
import LinkWrapper from "../reusable-components/link-wrapper"

import LeftArrowIcon from "../../images/icons/left-arrow"
import RightArrowIcon from "../../images/icons/left-arrow"

const getPageUrl = (pageNum, categoryBaseUrl) => {
  if (pageNum == 1) {
    return `${categoryBaseUrl}`
  }
  return `${categoryBaseUrl}page-${pageNum}/`
}

const navigateToPage = (pageNum, categoryBaseUrl) => {
  if (pageNum != "...") {
    navigate(getPageUrl(pageNum, categoryBaseUrl))
  }
}

const Pagination = ({ pageNumber, totalPages, categoryBaseUrl }) => {
  const pageButtons = []
  for (let i = pageNumber - 2; i <= totalPages && i <= pageNumber + 2; ++i) {
    if (i > 0) {
      pageButtons.push(i)
    }
  }
  if (pageButtons[0] != 1) {
    pageButtons.unshift("...")
    pageButtons.unshift(1)
  }
  if (pageButtons.slice(-1) != totalPages) {
    pageButtons.push("...")
    pageButtons.push(totalPages)
  }
  const prevPageUrl =
    pageNumber < 2 ? "" : getPageUrl(pageNumber - 1, categoryBaseUrl)
  const nextPageUrl =
    pageNumber >= totalPages ? "" : getPageUrl(pageNumber + 1, categoryBaseUrl)

  return (
    <PaginationStyling>
      {!prevPageUrl ? null : (
        <StyledLinkWrapper to={prevPageUrl} key="prev" className="prev">
          <LeftArrowIcon />
        </StyledLinkWrapper>
      )}
      {pageButtons.length <= 1
        ? null
        : pageButtons.map((pageButton, pageButtonIndex) => (
            <SubmitButton
              key={pageButtonIndex}
              text={pageButton}
              className={pageNumber == pageButton ? "button-active" : ""}
              callbackFunction={() => {
                navigateToPage(pageButton, categoryBaseUrl)
              }}
            />
          ))}
      {!nextPageUrl ? null : (
        <StyledLinkWrapper to={nextPageUrl} key="next" className="next">
          <RightArrowIcon />
        </StyledLinkWrapper>
      )}
    </PaginationStyling>
  )
}

export default Pagination

// ===============
//     STYLES
// ===============
const PaginationStyling = styled.div`
  button {
    padding: 13px 0;
    margin: 0 10px 0 0;
    max-width: 50px;
    text-align: center;
    width: 50px;
  }
  display: flex;
  justify-content: flex-end;
`
const StyledLinkWrapper = styled(LinkWrapper)`
  transition: all 0.2s ease-in-out;
  background-color: white;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  svg g line,
  svg g path {
    stroke: ${({ theme }) => theme.colors.black1};
    transition: all 0.2s ease-in-out;
    box-shadow: none;
  }
  svg {
    margin: auto;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: ${({ theme }) => theme.colors.grey};
    svg g line,
    svg g path {
      stroke: white;
      transition: all 0.2s ease-in-out;
    }
  }
  &.next {
    margin-left: 30px;
    svg {
      transform: scaleX(-1);
    }
  }
  &.prev {
    margin-right: 40px;
  }
`
